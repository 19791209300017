import {ValueObject} from "../ValueObject";
import {IAppDependantDescriptor, AppQuestion} from "../AppQuestion";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";
import {QuestionKey} from "../QuestionKey";

export interface IAppPageDefinition {

  title?: string;
  questionKeys: QuestionKey[];
  dependantDescriptor?: IAppDependantDescriptor;

  /**
   * for identification/persistence
   */
  sectionId?: string;

  /**
   * @deprecated do NOT use ... pages do not have sub-pages/sub-sections
   */
  subSections: IAppPageDefinition[];
}


export class AppPageDefinition extends ValueObject<IAppPageDefinition> {

  private _log: ILogger = LoggerFactory.build( 'AppPageDefinition' );


  /**
   * @deprecated a hack/work-around from a merge of an older class
   */
  questions: AppQuestion[] = [];

  /**
   * @deprecated a hack/work-around from a merge of an older class
   */
  questionsByKey: {[key: string]: AppQuestion} = {};


  public static toValues(appPageModel: AppPageDefinition[] ): IAppPageDefinition[] {

    const answer: IAppPageDefinition[] = [];

    for( const section of appPageModel ) {

      answer.push( section.value )
    }

    return answer;
  }


  protected onSetValue(value: IAppPageDefinition | null) {
  }

  /**
   * @deprecated a hack/work-around from a merge of an older class
   */
  initQuestionFields( questionsByKey: {[key: string]: AppQuestion} ) {

    for( const questionKey of this.value.questionKeys ) {
      const q = questionsByKey[ questionKey ];
      if( !q ) {

        if( 'Bk3dP' === questionKey ) {

          this._log.error( '!q', 'questionKey', questionKey, 'questionsByKey', questionsByKey );
        } else {

          // this._log.error( '!q', 'questionKey', questionKey );
        }
        continue;
      }
      this.questions.push( q );
      this.questionsByKey[ q.value.key ] = q;
    }

  }

  constructor( value: IAppPageDefinition ) {

    super(value);
  }

}



