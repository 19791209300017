


export class BrowserAppEvaluationToolInfo {

  public version = '2024.3.22.1';

  constructor() {
  }

}
